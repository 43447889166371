(($, books) => {
  'use strict';
  var time = 5000; // 10000
  // var timerId = null;
  var $mainVisual = null;
  var $mainVisualPc = null;
  var $mainVisualSp = null;
  var oldIndex = -1;
  var newIndex = -1;

  var replaceCurrentImage = () => {
    // 同一の書籍が連続で呼ばれないようにする
    do {
      newIndex = Math.floor(Math.random() * $mainVisualPc.find('li').length);
    } while (oldIndex === newIndex);

    // サムネイルの点滅
    $mainVisualPc
      .find('li:nth-child(' + (newIndex + 1) + ')')
      .animate({ opacity: 0.1 }, 150)
      .animate({ opacity: 1 }, 750);

    // 画像の入れ替え
    $mainVisual
      .find('a.currentImage')
      .attr('href', books[newIndex].url)
      .find('span')
      .css({
        'background-image': 'url(' + books[newIndex].src + ')',
      });

    // 表示中画像のindex保持
    oldIndex = newIndex;

    // 次アニメーションの呼出
    setTimeout(replaceCurrentImage, time);
  };

  var initialize = () => {
    $mainVisual = $('#mainVisual');

    // pc
    $mainVisualPc = $('#mainVisual div.mainVisualPc');
    $mainVisualPc
      .html('<ul>' +
        books.map((book, index) => {
          var imageClass = index <= 3 ? 'class="large"' : 'class="small"';
          return `<li ${imageClass}><a href="${book.url}" style="background-image: url(${book.src});">&nbsp;<!-- img src="${book.src}" --></a></li>`;
        }).join('') +
        '</ul>'
      );

    $mainVisualPc
      .append('<a class="currentImage"><span></span></a>');

    replaceCurrentImage();

    // sp
    $mainVisualSp = $('#mainVisual div.mainVisualSp');
    $mainVisualSp
      .html('<ul>' +
        books.map((book, index) => {
          return `<li><a href="${book.url}"><img src="${book.src}"></a></li>`;
        }).join('') +
        '</ul>'
      );

    $mainVisualSp
      .find('ul')
      .slick({
        'initialSlide': Math.floor(Math.random() * $mainVisualSp.find('li').length),
        'autoplay': true,
        'autoplaySpeed': 3000,
        'useCSS': false,
        'focusOnSelect': true,
        'centerMode': true,
        'centerPadding': '28%',
        'slidesToShow': 1,
        'arrows': false,
        'speed': 250,
        'mobileFirst': true,
        'responsive': [{
          'breakpoint': 520,
          'settings': {
            'centerPadding': '7%',
            'slidesToShow': 3,
          },
        }],
      });

  };

  $(() => {
    initialize();
  });
})(jQuery, books);
